import colors from 'src/theme/colors';

export default function PadlockOpen({ color = colors.podcastShade500 }: { color?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" fill="none">
      <path
        fill={color}
        fillRule="evenodd"
        d="M2 3a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v3H8V3a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v5h5a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-6c0-1.306.835-2.417 2-2.83V3Zm7 7H3a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1Zm-3 2a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
