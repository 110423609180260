import colors from 'src/theme/colors';

export default function PadlockClosed({ color = colors.podcastShade500 }: { color?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="12 5 12 22" fill="none">
      <path
        stroke={color}
        strokeWidth="2"
        d="M15 14h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2ZM15 14v-3a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v3"
      />
      <rect width="2" height="4" x="17" y="17" fill={color} rx="1" />
    </svg>
  );
}
